<template>
  <div class="home-cat-bar">
    <div class="cat-list">
      <div @click="handleScroll('.recommend-section')" class="cat-item">
        <div style="border-bottom: 1px solid #333;padding-bottom: 10px">推荐商品</div>
      </div>
      <div @click="handleScroll('.category-goods-' + item.id)" v-for="(item, index) in catList" :key="index" class="cat-item">
        <div style="border-bottom: 1px solid #333;padding-bottom: 10px">{{item.name}}</div>
      </div>
    </div>
    <div @click="goTop" class="go-top">
      <i class="el-icon-arrow-up"></i>
      <div class="text">顶部</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    catList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  mounted () {
    const _this = this
    this.$nextTick(() => {
      window.onscroll = () => {
        _this.setCatBarPosition()
        _this.setCatBarActive()
      }
    })
  },
  methods: {
    setCatBarActive () {
      // const classNameArr = []
      const elements = document.querySelectorAll('.category-goods')
      for (let i = 0; i < elements.length; i++) {
        const eTop = elements[i].offsetTop + 220
        const oTop = document.body.scrollTop || document.documentElement.scrollTop
        if (eTop - oTop > 50) {
          const catItems = document.querySelectorAll('.home-cat-bar .cat-list .cat-item')
          catItems.forEach(item => {
            item.classList.remove('active')
          })
          catItems[i].classList.add('active')
          break
        }
      }
    },
    setCatBarPosition () {
      const catBarEle = document.querySelector('.home-cat-bar')
      const eTop = catBarEle.parentElement.offsetTop
      const oTop = document.body.scrollTop || document.documentElement.scrollTop
      const eHeight = catBarEle.offsetHeight
      const clientHeight = window.innerHeight||document.documentElement.clientHeight||document.body.clientHeight
      if ((eTop - oTop) < (clientHeight - eHeight) / 2) {
        // document.querySelector('.home-cat-bar').style.top = Math.abs(eTop - oTop) + (clientHeight - eHeight) / 2 + 'px'
        catBarEle.style.position = 'fixed'
        catBarEle.style.top = '50%'
        catBarEle.style.transform = 'translateY(-50%)'
      } else {
        catBarEle.style.position = 'absolute'
        catBarEle.style.transform = 'translateY(0)'
        document.querySelector('.home-cat-bar').style.top = '50px'
      }
    },
    // 回到顶部
    goTop () {
      this.scrollEvent(0)
    },
    handleScroll(className) {
      const pos = document.querySelector(className).offsetTop
      console.log(pos)
      const pageHeight = document.documentElement.offsetHeight || document.body.offsetHeight
      const clientHeight = window.innerHeight||document.documentElement.clientHeight||document.body.clientHeight
      if (pos + clientHeight - 50 > pageHeight) {
        this.scrollEvent(pageHeight - clientHeight)
      } else {
        this.scrollEvent(pos - 50)
      }
    },
    scrollEvent (pos) {
      let timer = null
      cancelAnimationFrame(timer)
      timer = requestAnimationFrame(function fn(){
        const oTop = document.body.scrollTop || document.documentElement.scrollTop
          if(oTop > pos + 50){
            scrollTo(0, oTop - 50)
            timer = requestAnimationFrame(fn)
          } else if (oTop < pos - 50) {
            scrollTo(0, oTop + 50)
            timer = requestAnimationFrame(fn)
          } else {
            scrollTo(0, pos)
            timer = requestAnimationFrame(fn)
            cancelAnimationFrame(timer)
          }
        })
    }
  }
      // document.querySelector(className).scrollIntoView({ block: 'start', behavior: 'smooth' })
}
</script>
<style lang="scss" scoped>
.home-cat-bar {
  width: 60px;
  .cat-list {
    .cat-item {
      position: relative;
      width: 100%;
      //background-color: #D9262C;
      //border-bottom-width: 1px;
      //border-bottom-color: #333;
      //border-bottom-style: solid;
      //color: #ffffff;
      padding: 0 10px;
      margin-bottom: 10px;
      line-height: 1.3;
      cursor: pointer;
      text-align: center;
      //border-radius: 3px;
      &.active {
        //background-color: rgb(255, 168, 0);
        color: #D9262C;
      }
      &:last-child {
        border-bottom: 0 none;
      }
      &:hover {
        //background-color: rgb(255, 168, 0);
        color: #D9262C;
      }
      //&.active:after{
      //  background-color: #D9262C;
      //}
      //&.hover:after{
      //   background-color: #D9262C;
      // }
      //&:after {
      //  content: '';
      //  position: absolute;
      //  bottom: 0;
      //  left: calc((100% - 30px) / 2);
      //  background-color: #333;
      //  width: 30px;
      //  height: 1px;
      //}
    }
  }
  .go-top {
    color: #333;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 3px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //background-color: rgb(255, 168, 0);
    padding: 8px 10px 13px;
    .el-icon-arrow-up {
      font-size: 20px;
    }
  }
}
</style>
