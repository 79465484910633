<template>
  <div id="sidebar">
    <div class="sidebar-content">
<!--      <div class="sidebar-item-wrap">-->
<!--        <div class="sidebar-item user">-->
<!--          <span class="icon iconfont icon-denglu"></span>-->
<!--        </div>-->
<!--        <div style="font-size: 10px">个人中心</div>-->
<!--        <div @click="jumpHandle('/user/index')" class="sidebar-item-content">个人中心</div>-->
<!--      </div>-->
      <div @click="jumpHandle('/user/collect')" class="sidebar-item-wrap">
        <div class="sidebar-item shoucang">
          <img src="../../assets/images/sidebar/collect.png" alt="">
<!--          <span class="icon iconfont icon-shoucangfuben"></span>-->
        </div>
        <div style="font-size: 10px;text-align: center;margin-bottom: 6px">
          收藏
        </div>
<!--        <div class="sidebar-item-content">收藏夹</div>-->
      </div>
      <div class="sidebar-item-wrap">
        <div class="sidebar-item gouwuche"  @click="jumpHandle('/user/shopping')">
<!--          <span class="num">{{ shopCarNumber }}</span>-->
<!--          <span class="icon iconfont icon-03"></span>-->
          <img src="../../assets/images/sidebar/shopCar.png">
          <div style="font-size: 10px;text-align: center;margin-bottom: 6px">购物车</div>
        </div>
      </div>
      <a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=800090801&site=qq&menu=yes" class="sidebar-item-wrap">
        <div class="sidebar-item kefu" style="background-color: #fff">
<!--          <span class="icon iconfont icon-10"></span>-->
          <img src="../../assets/images/sidebar/customerService.png">
          <div style="font-size: 10px;text-align: center;margin-bottom: 6px">客服</div>
<!--          <span>联</span><span>系</span><span>客</span><span>服</span>-->
        </div>
      </a>
      <div class="sidebar-item-wrap gongzhonghao">
        <div class="weixin">
<!--          <span class="icon iconfont icon-weixin"></span>-->
          <div style="text-align: center">
            <img src="../../assets/images/sidebar/weChat.png">
          </div>
          <div style="font-size: 10px;text-align: center;margin-bottom: 6px">微信</div>

        </div>
        <div class="sidebar-item-content code-box">
          <img src="../../assets/images/common/f195ede4e87f074bda6b231fdac09085.jpg" alt="">
          <div clss="text">关注微信公众号<br />手机查询订单更方便</div>
        </div>
      </div>
      <!-- <div class="sidebar-item-wrap">
        <div class="sidebar-item bangzhu">
          <span class="icon iconfont icon-shiyongbangzhu"></span>
        </div>
        <div class="sidebar-item-content">使用指南</div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {}
  },
  computed: mapState(['shopCarNumber']),
  methods: {
    jumpHandle (path) {
      this.$router.push({ path: path })
    }
  }
}
</script>
<style lang="scss" scoped>
#sidebar {
  width: 40px;
  height: 100vh;
  //background-color: #262626;
  position: fixed;
  top: 0;
  right: 0;
  color: #333;
  z-index: 999;
  .sidebar-content {
    width: 100%;
    margin-top: 150px;
    a {
      text-decoration: none;
      color: #333;
    }
    .sidebar-item-wrap {
      margin-bottom: 2px;
      background-color: #fff;
      border-bottom: 1px solid #999;
      position: relative;
      // overflow: hidden;
      &.gongzhonghao {
        border-top: 1px solid #999;
        padding-top: 4px;
        .sidebar-item-content {
          height: 200px;
          width: 0;
          line-height: 1.5;
          background-color: #ffffff;
          overflow: hidden;
          white-space: nowrap;
          //border-bottom: 1px solid #999;
          img {
            width: 150px;
            vertical-align: middle;
          }
          .text {
            width: 150px;
            overflow: hidden;
          }
        }
      }
      .sidebar-item-content {
        position: absolute;
        right: 40px;
        height: 100%;
        top: 50%;
        transform: translateY(-50%);
        background-color: #fcfcfc;
        color: #666666;
        box-sizing: border-box;
        width: 0;
        text-align: center;
        line-height: 38px;
        font-size: 14px;
        z-index: 1;
        transition: width .3s;
        cursor: pointer;
        overflow: hidden;
        border: 1px solid #e0e0e0;
        opacity: 0;
        // display: none;
      }
      &:hover {
        color: #D9262C;
        .sidebar-item-content {
          width: 120px;
          opacity: 1;
          &.code-box {
            width: 150px;
          }
        }
      }
    }
    .sidebar-item {
      position: relative;
      z-index: 2;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex-wrap: wrap;
      height: 40px;
      box-sizing: border-box;
      font-size: 12px;
      cursor: pointer;
      //background-color: #d9262c;
      &.user {
        .iconfont {
          font-size: 21px;
        }
      }
      &.shoucang {
        .iconfont {
          font-size: 17px;
        }
      }
      &.bangzhu {
        .iconfont {
          font-size: 18px;
        }
      }
      &.weixin {
        .iconfont {
          font-size: 20px;
        }
      }
      &.kefu {
        .iconfont {
          font-size: 20px;
        }
      }
      &.gouwuche, &.kefu {
        height: auto;
        //padding: 10px 5px;
        line-height: 20px;
        .num {
          background-color: #ff3d3d;
          width: 16px;
          text-align: center;
          line-height: 16px;
          border-radius: 8px;
          margin-bottom: 5px;
          margin-left: 1px;
        }
      }
      &:hover {
        //background-color: #D9262C;
        color: #D9262C;
      }
      &.active {
        //background-color: #D9262C;
        color: #D9262C;
      }
    }
  }
}
</style>
