import httpRequst from "../utils/request";
// 购物车列表
export const getShopingList = function(params) {
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/api/shoping-cart`, params)
}

export const getHotKeywordsList = function(params) {
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/common/hot-keywords`, params)
}

//添加购物车
export const addShopingCart = function(params) {
    return httpRequst.post(`${process.env.VUE_APP_BASEURL}/api/shoping-cart`, params)
}
//获取最新商品数量
export const getNewQuantity = function(params) {
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/api/shoping-cart/get-new-quantity`, params)
}

// 转为订单

export const convertOrder = function(params) {
    return httpRequst.post(`${process.env.VUE_APP_BASEURL}/api/shoping-cart/convert-order`, params)
}
// 立即购买

export const buyNow = function(params) {
    return httpRequst.post(`${process.env.VUE_APP_BASEURL}/api/orders/buy-now`, params)
}

//确认单详情
export const ordersConfirm = function(params) {
    return httpRequst.post(`${process.env.VUE_APP_BASEURL}/api/orders/confirm`, params)
}
// 收货地址 api/address
export const addressList = function(params) {
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/api/address`, params)
}
// 提交订单
export const submitOrders = function(params) {
    return httpRequst.post(`${process.env.VUE_APP_BASEURL}/api/orders`, params)
}

// 转为报价单
export const quotationPrice = function(params) {
    return httpRequst.post(`${process.env.VUE_APP_BASEURL}/api/shoping-cart/quotation-price`, params)
}

// 删除商品
export const delShopping = function(params) {
    return httpRequst.delete(`${process.env.VUE_APP_BASEURL}/api/shoping-cart/${params}`, params)
}
// 批量删除商品
export const delShoppings = function(params) {
    return httpRequst.delete(`${process.env.VUE_APP_BASEURL}/api/shoping-cart/batch`, params)
}
// 清空商品
export const clearShoppings = function(params) {
    return httpRequst.delete(`${process.env.VUE_APP_BASEURL}/api/shoping-cart/clear`, params)
}
// 批量收藏
export const collects = function(params) {
    return httpRequst.post(`${process.env.VUE_APP_BASEURL}/api/goods/multi-collect`, params)
}
// 推荐商品
export const recommandGoods = function(params) {
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/api/recommand-goods`, params)
}
// 修改商品
export const updateShopping = function(params) {
    return httpRequst.put(`${process.env.VUE_APP_BASEURL}/api/shoping-cart/${params.id}`,params)
}
