<template>
	<div class="main-header-left">
		<div v-if="!userInfo.id" style="display: flex">
			<div class="welcome">{{ '您好！欢迎来到鹏隆' }}</div>
			<!--      <div v-if="userInfo && userInfo.id" class="logged">-->
			<!--        <router-link class="logged-item" to="/user/index">{{userInfo.name}}</router-link>&nbsp;&nbsp;<span @click="signout" style="cursor: pointer;" class="logged-item" to="/">[退出]</span>-->
			<!--      </div>-->
			<div class="not-logged" style="display: flex">
				<span class="d-login" style="border-left: 2px solid #333;padding-left: 16px;margin-left: 16px;"
				      @click="$router.push('/login')">登录</span>
				<!--      <span style="border-left: 2px solid #D9262C;padding-left: 16px;margin-left: 16px;color: #D9262C" @click="$router.push('/regist')">注册</span>-->

				<div style="position: relative;border-left: 2px solid #333;padding-left: 16px;margin-left: 16px;"
				     :class=" isShowTip ? 'resTip' : ''">
					<span class="d-login" @click="$router.push('/regist')">注册</span>
					<div v-show="isShowTip"
					     style="background-color: #D9262C;color: #fff;padding: 4px 10px;border-radius: 20px;position: absolute;width: 260px;bottom: -34px;left: 10px;font-size: 14px">
						马上注册会员，享受更多惊喜优惠！<span style="margin-left: 6px;font-size: 16px;cursor: pointer"
						                                    @click="isShowTip = false">x</span>
					</div>
				</div>

				<!--       <router-link class="btn" to="/login">登录</router-link>-->
				<!--       <router-link class="btn" to="/regist">注册</router-link>-->
			</div>
		</div>
		<div v-else>

			<el-popover
					placement="top-start"
					width="240"
					trigger="hover"
			>
				<template slot="reference">
					<div style="display:flex;align-items: center">
						<div>{{ userInfo.name }}</div>
						<div class="companyType">{{ userInfo.company_type == 1 ? '认证企业' : '普通企业' }}</div>
						<div style="margin-left: 4px"><i class="el-icon-arrow-down"></i></div>
					</div>
				</template>

				<template>
					<div style="display:flex;align-items: center">
						<div style="width: 50px;height: 50px;margin-right: 10px">
							<img style="width: 100%;height: 100%;" :src="userInfo && userInfo.avatar || defaultAvatar" alt="">
						</div>
						<div style="color: #333">
							<div>{{ userInfo.name }}</div>
							<div>{{ userInfo.company_name }}</div>
						</div>
					</div>
					<div style="line-height: 24px;cursor: pointer;margin-top: 10px"
					     @click="$router.push('/user/account/company')">个人中心
					</div>
					<div v-if="userInfo.company_type != 1" style="line-height: 24px;cursor: pointer"
					     @click="$router.push('/user/account/company')">升级企业用户
					</div>
					<div style="border-top: 1px dashed #999;padding-top: 10px;margin-top: 10px;text-align: center;cursor: pointer"
					     @click="signout"
					>退出
					</div>
				</template>

			</el-popover>
		</div>

	</div>
</template>
<script>
import {mapState} from 'vuex'
import defaultAvatar from '../../assets/images/common/default_avatar.png'

export default {
	data() {
		return {
			isLogin: false,
			isShowTip: true,
			defaultAvatar
		}
	},
	computed: {
		...mapState([
			'userInfo'
		]),
	},
	methods: {
		signout() {
			this.$confirm('确定退出登录嘛?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				await this.$store.dispatch('signout')
			})

			// this.$router.push({ path: '/login' })
		}
	}
}
</script>
<style lang="scss" scoped>

.resTip:after {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	border-bottom: 16px solid #D9262C;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	bottom: -14px;
	left: 26px;
}

.main-header-left {
	display: flex;
	align-items: center;

	.logged {
		margin-left: 20px;

		.logged-item {
			color: #ffffff;
			text-decoration: none;
		}
	}

	.not-logged {
		.btn {
			display: inline-block;
			color: #ffffff;
			background-color: #2877EC;
			line-height: 25px;
			padding: 0 14px;
			border-radius: 25px;
			text-decoration: none;
			margin-left: 20px;
		}
	}
}

.companyType {
	margin-left: 10px;
	background: url("../../assets/images/common/userInfoBac.png");
	padding: 4px 10px;
	color: #fff;
}

.d-login {
	color: #D9262C;
	cursor: pointer;
	&:hover {
		text-decoration:underline;
	}
}

</style>
