<template>
  <div id="main-header">
    <div class="page-content-wrap">
      <MainHeaderLeft />
      <MainHeaderNav />
    </div>
  </div>
</template>
<script>
import MainHeaderLeft from './main_header_left.vue'
import MainHeaderNav from './main_header_nav.vue'
import axios from 'axios'
import { mapState } from 'vuex'
export default {
  name: 'mainHeader',
  components: {
    MainHeaderLeft,
    MainHeaderNav
  },
  created () {
    this.getUserInfo()
  },
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  methods: {
    getUserInfo () {
      const token = localStorage.getItem('token')
      if (token && !this.userInfo.id) {
        axios.get(process.env.VUE_APP_BASEURL + '/api/userinfo', {
          headers: { Authorization: 'Bearer ' + token }
        }).then(data => {
          this.$store.dispatch('setUserInfo', data.data)
        }).catch(err => {
          // console.log(err)
          return err
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#main-header {
  width: 100%;
  //background-color: #D9262C;
  background-color: #f5f5f5ff;
  color: black;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 999;
  box-shadow: 0 2px 4px 2px rgb(0 0 0 / 4%);
  .page-content-wrap {
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
