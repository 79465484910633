<template>
  <div :style="{backgroundImage: 'url('+backgroundImage+')'}" class="home-list-1">
    <div class="page-content-wrap" style="width: 1200px">
      <div class="header">
        <div class="title">{{data.name}}</div>
        <div style="display: flex;flex-direction: row;align-items: center">
          <div v-for="item in data.children.slice(0, 6)" :key="item.id" style="margin-right: 20px;">
            <div class="itemNameClass" @click="$router.push(`/goods/list?id=${item.id}`)">{{ item.name }}</div>
          </div>
          <div @click="jumpCategoryList(data.id)" class="more">
            <span style="color: #999" >更多 ></span>
<!--            <span class="icon iconfont icon-hangdongjiantou"></span>-->
          </div>
        </div>
      </div>
      <div class="list">
        <div @click="jumpDetail(item.id)" v-for="(item, index) in data.goods.slice(0, 8)" :key="index" class="list-item">
          <div class="goods-pic">
            <img :src="item.cover" alt="">
          </div>
          <div class="title">{{item.name}}</div>
          <!-- <div class="desc">导向轴</div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    backgroundImage: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {}
  },
  methods: {
    // 跳转详情
    jumpDetail (id) {
      this.$router.push({ path: '/goods/detail', query: { id: id }})
    },
    // 跳转分类列表
    jumpCategoryList (id) {
      this.$router.push({ path: '/category/list', query: { id: id } })
    }
  }
}
</script>
<style lang="scss" scoped>
.home-list-1 {
  background-color: #f5f5f5;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 38px 0;
  //padding-top: 38px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 20px;
      font-weight: 600;
    }
    .more {
      font-size: 16px;
      cursor: pointer;
      .iconfont {
        margin-left: 6px;
        font-size: 14px;
      }
    }
    .itemNameClass {
      cursor: pointer;
      &:hover {
        color: #D9262C;
      }
    }
  }
  .list {
     //background-color: #A8A8A8;
    //border: 1px solid #A8A8A8;
    //border-collapse: collapse;
    // border-radius: 2px;
    margin-top: 20px;
    //padding-left: -2px;
    //overflow: hidden;

    display: flex;
    flex-wrap: wrap;
    .list-item {
      width: 301px;
      //flex: 1;
      //flex-grow: 4;
      background-color: #ffffff;
      // height: 250px;
      box-sizing: border-box;
      padding: 19px;
      border: 1px solid #A8A8A8;
      margin: -1px 0 0 -1px;
      //border-collapse: collapse;
      //padding-right: -1px;
      //border-right: 1px solid #A8A8A8;
      // box-shadow: 0 6px 12px 0 rgba(69, 74, 99,  0.08);
      transition: all  .2s;
      &:hover {
        .goods-pic {
          img {
            transform: scale(1.2);
          }
        }
      }
      cursor: pointer;
      //&:nth-child(n + 5) {
      //  margin: -1px 0 0 -1px;
      //}
      //&:nth-child(n - 4) {
      //  margin: -1px 0 0 -1px;
      //}
      //&:nth-child(-n + 6) {
      //  border-top: 0 none;
      //}
      //&:nth-child(6n), &:last-child {
      //  border-right: 0 none;
      //}
      .goods-pic {
        width: 268px;
        height: 160px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          max-height: 100%;
          transition: all .3s;
        }
      }
      .title {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: center;
        margin-top: 20px;
      }
      .desc {
        text-align: center;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        color: #acacac;
        margin-top: 10px;
        font-size: 13px;
      }
    }
  }
}
</style>
