<template>
	<div @click.stop="nothingHandle" class="common-top-search">
		<div class="main">
			<div>
				<div style="display: flex">
					<div class="search-container">
						<div class="input-container" ref="inputRef">
							<input @keyup.enter="searchHandle" v-model="keywords" type="text" placeholder="输入产品名称/产品型号搜索/同行代码"
								@focus="showSR = true">
							<div v-show="showSR" class="search-result">
								<div v-if="codeData.length > 0" class="list">
									<div @click="jumpDetail2(item)" v-for="(item, index) in codeData"
										:key="`${index}-${item.pro_code_id}`" class="list-item" @mouseover="showGoods(item, $event)"
										@mouseleave="showDetail = false">
										{{ item.search_result }}
									</div>
								</div>
								<div v-else-if="goodsData.length > 0" class="list">
									<div @click="jumpDetail(item)" v-for="(item, index) in goodsData" :key="`${index}-${item.goods_id}`"
										class="list-item">
										{{ item.search_result }}
									</div>
								</div>
							</div>
						</div>
						<div @click="searchHandle" class="search-btn">
							<span class="icon iconfont icon-fenxiang"></span>
							<span style="margin-left: 10px">搜 索</span>
						</div>
						<div @click="$router.push(`/quote`)"
							style="text-align: center;display: flex;align-items: center;border-radius: 0 2px 2px 0;margin-left: 30px;border: 1px solid #D9262C;padding: 10px;color: #D9262C;width: 120px;height: 44px">
							<div>
								<img src="../../assets/images/common/quotationHeaderIcon.png" alt="">
							</div>
							<div class="ksbj">快速报价</div>
						</div>
					</div>
					<Buttons />
				</div>
				<div class="hotwordsContainer">
					<div v-if="hotKeyWords[0]" class="hotwords">
						<div @click="hotSearch(item.name)" v-for="(item, index) in hotKeyWords" :key="index" class="hot-item">
							{{ item.name }}
						</div>
					</div>
				</div>
			</div>
			<div>
				<div style="width: 100px;height: 100px">
					<img style="width: 100%;height: 100%" src="../../assets/images/common/f195ede4e87f074bda6b231fdac09085.jpg"
						alt="">
				</div>
				<div style="color: #333;font-size: 10px;margin-top: 4px;width: 120px;font-weight: 400;">
					订单/优惠码上掌握
				</div>
			</div>
		</div>

		<div v-show="showDetail" class="productDetailContent" :style="{ top: detailTop + 'px', left: detailLeft + 'px' }"
			@mouseover="showDetail = true" @mouseleave="showDetail = false" @click="jumpDetail2(goods)">
			<div class="contentLeft">
				<img v-if="goods && goods.goodsDetail" :src="goods.goodsDetail.pic_group[0]">
			</div>
			<div v-if="goods" class="contentRight">
				<div class="goods-title">导向轴 一端台阶型/一端台阶内螺纹型 g6</div>
				<div v-if="goods.goodsDetail && goods.goodsDetail.condition" class="goods-item">
					<div class="label">条件筛选：</div>
					<div class="content">
						<div v-for="(item, index) in goods.goodsDetail.condition" :key="index" class="data-item">
							<span>{{ item.parent.name }} </span>
							<span class="light-color">{{ item.name }}</span>
						</div>
					</div>
				</div>
				<div class="goods-item">
					<div class="label">产品代码：</div>
					<span class="light-color one-line">{{ goods.search_result }}</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Buttons from './buttons'
import Store from "@/store";

export default {
	components: {
		Buttons
	},
	props: {
		value: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			hotKeyWords: Store.getters.getHotKeywordsList,
			keywords: '',
			goodsData: [], // 商品列表
			codeData: [], // 代码列表
			timeout: null,
			requestId: 0,
			listTemp: {},
			selectionTemp: {},
			showSR: false,
			searchParam: null,
			detailTop: 0,
			detailLeft: 0,
			showDetail: false,
			goods: null,
		}
	},
	created() {
		if (this.value) {
			this.keywords = this.value
		}
		// this.getHotKeywords()
	},
	mounted() {
		const _this = this
		this.$nextTick(() => {
			document.body.addEventListener('click', function () {
				_this.showSR = false
			})
		})
		if (this.$refs.inputRef) {
			this.detailLeft = this.$refs.inputRef.offsetLeft + 320;
		}
	},
	watch: {
		keywords() {
			if (!this.showSR) this.showSR = true;
			if (this.timeout) clearTimeout(this.timeout);
			this.timeout = setTimeout(this.changeWord, 500);
		}
	},
	methods: {
		nothingHandle() {
			return false
		},
		jumpDetail(item) {
			console.log(item)
			let query = {
				id: item.goods_id
			}
			if (item.production_model) {
				query.production_model = item.production_model
			}
			// const {href} = this.$router.resolve({path: `/goods/detail/`, query: query})
			const { href } = this.$router.resolve({ path: `/search/result?`, query: { keywords: item.search_result } })
			// this.$router.push({ path: `/news/detail/${item.id}` })
			window.open(href, '_blank')
			// this.$router.push({path: '/goods/detail', query: {id: item.goods_id, production_model: item.production_model}})
		},
		jumpDetail2(item) {
			console.log(item)
			if (!item) return;
			this.showSR = false;
			this.$router.push({
				path: '/goods/detail', query: {
					id: item.goods_id,
					code: item.pro_code_id,
					selection: item.paramMap ? JSON.stringify(item.paramMap) : '',
				}
			})
		},
		async getList(keywords) {
			if (!this.listTemp[keywords]) {
				const res = await this.$http.post('api/goods/search-option', { keywords: keywords })
				const goods = [];
				const codes = [];
				if (res?.length) {
					res.forEach(i => {
						if (i.pro_code_id) codes.push(i);
						else if (i.goods_id) goods.push(i);
					})
				}
				this.listTemp[keywords] = { goods, codes };
			}
			return Object.assign({}, this.listTemp[keywords]);
		},
		hotSearch(name) {
			this.$router.push({ path: '/search/result', query: { keywords: name } })
		},
		searchHandle() {
			if (this.keywords.trim()) {
				this.showSR = false;
				if (this.codeData.length === 1) {
					const item = this.codeData[0];
					this.$router.push({
						path: '/goods/detail', query: {
							id: item.goods_id,
							code: item.pro_code_id,
							selection: item.paramMap ? JSON.stringify(item.paramMap) : '',
						}
					})
				} else if (this.codeData.length > 1 && this.searchParam) {
					this.$router.push({
						path: '/goods/detail', query: {
							id: this.searchParam.goods_id,
							code: this.searchParam.pro_code_id,
							selection: this.searchParam.paramMap ? JSON.stringify(this.searchParam.paramMap) : '',
						}
					})
				} else {
					this.$router.push({ path: '/search/result', query: { keywords: this.keywords.trim() } })
				}
			} else {
				this.$message({
					type: 'error',
					message: '请输入搜索关键词',
					duration: 1500
				})
			}
		},
		async getHotKeywords() {
			const res = await this.$http.get('/common/hot-keywords', { limit: 5 })
			this.hotKeyWords = res
		},
		async getSelection(goodsId, code) {
			const key = `${goodsId}-${code}`;
			if (!this.selectionTemp[key]) {
				const res = await this.$http.post('/api/goods/selection', {
					goods_id: goodsId,
					pro_code_id: code,
					params_selection: [],
					pro_selection: [],
					optional_processing: [],
					production_model: ''
				});
				this.selectionTemp[key] = res?.pro_selection2?.filter(i => i.goods_param_id === code && i.param_type != 3 && i.var_type == 1).sort((a, b) => a.sort - b.sort) || [];
			}
			return [...this.selectionTemp[key]];
		},
		async changeWord() {
			const requestId = ++this.requestId;
			const keywords = this.keywords;
			if (!keywords || keywords.length < 2) {
				this.listTemp = {};
				this.selectionTemp = {};
			}
			if (!keywords) {
				this.goodsData = [];
				this.codeData = [];
				this.searchParam = null;
				return;
			}
			this.getList(keywords).then(res => {
				if (requestId !== this.requestId) return;
				this.goodsData = res.goods
			});

			const arr = keywords.split('-');
			let code = '';
			let codeData = [];
			for (let i = 0; i < arr.length; i++) {
				const word = arr[i];
				const newCode = i === 0 ? word : `${code}-${word}`;
				if (i === 0) {
					const { codes } = await this.getList(newCode);
					if (requestId !== this.requestId) return;
					if (!codes.length) {
						break;
					} else {
						code = newCode;
						codeData = codes;
					}
				} else {
					const filterArr = codeData.filter(i => i.pro_code.toUpperCase().indexOf(newCode.toUpperCase()) === 0);
					if (!filterArr.length) {
						codeData = codeData.filter(i => i.pro_code.toUpperCase() === code.toUpperCase());
						break;
					} else {
						code = newCode;
						codeData = filterArr;
					}
				}
			}
			const paramStr = keywords.replace(code, '');
			if (!code || !codeData.length || !paramStr) {
				this.codeData = codeData;
				return;
			}
			let selection = await this.getSelection(codeData[0].goods_id, codeData[0].pro_code_id);
			if (requestId !== this.requestId) return;
			if (!selection?.length) {
				this.codeData = codeData;
				return;
			}
			const paramList = keywords.replace(code, '').split('-').filter(i => !!i);
			const paramMap = {};
			const colName = [];
			const colNameVal = [];
			const hideArr = [];
			for (let i = 0; i < selection.length; i++) {
				const s = selection[i];
				const p = paramList[i];
				colName.push(s.product_param);
				if (p) {
					if (p.indexOf(s.product_param) !== 0) {
						this.codeData = codeData;
						return;
					}
					const v = p.replace(s.product_param, '')
					colNameVal.push(v);
					paramMap[s.id] = v;
					if (v && s.filter_tzsf && s.product_param) {
						let TZSFResult = this.dealTZSF(s.filter_tzsf, colName, colNameVal, s.product_param)
						if (TZSFResult) {
							let allArr = s.filter_quote_params.split(",")
							TZSFResult = `${TZSFResult}`
							const showarr = TZSFResult.replaceAll("\"", "").split(",");
							allArr = allArr.filter(i => !showarr.some(j => j == i));
							hideArr.push(...allArr)
						}
					}
				} else {
					colNameVal.push(null);
				}
			}
			if (hideArr.length > 0) {
				selection = selection.filter((item) => !hideArr.some(i => i == item.product_param))
			}
			if (Object.keys(paramMap).some(i => !selection.some(j => j.id == i))) {
				this.codeData = codeData;
				return;
			}
			for (let i = 0; i < selection.length; i++) {
				const item = selection[i];
				const paramVal = paramMap[item.id];
				if (item.var_type == 1) {
					let TZSFResult;
					if (item.tzsf && item.param_type != 3) {
						TZSFResult = this.dealTZSF(item.tzsf, colName, colNameVal, item.product_param)
					}
					if (item.param_type === 1) {
						const arrstr = TZSFResult ? `${TZSFResult}` : item.specific_param;
						let type1_arr = [...new Set(arrstr.split(","))].map(i => {
							return {
								id: item.id,
								select_value: i
							}
						})
						if (paramVal && !type1_arr.some(i => i.select_value == paramVal)) {
							this.codeData = codeData;
							return;
						}
						item.specific_param2 = type1_arr
					} else if (item.param_type === 2) {
						let addtion = []
						let initRange = this.getRangeObj(item.specific_param, /(\d+(\.\d+)?)~(\d+(\.\d+)?)\/(\d+(\.\d+)?)/)
						const temp = { id: item.id, addtion }
						if (initRange.isMatch) {
							Object.assign(temp, {
								max: Number.parseFloat(initRange.max),
								min: Number.parseFloat(initRange.min),
								step: Number.parseFloat(initRange.step)
							})
							if (paramVal) {
								let { checkNumInRangeBool } = this.isInRnnge(temp.min, temp.max, temp.step, addtion, paramVal, false)
								if (!checkNumInRangeBool) {
									this.codeData = codeData;
									return;
								}
							}
						}
						if (TZSFResult && TZSFResult != "0") {
							TZSFResult = TZSFResult.toString();
							TZSFResult = `[${TZSFResult}]`
							addtion.push(...JSON.parse(TZSFResult.replace(/\[\d+(\.\d+)?\:\d+(\.\d+)?\/\d+(\.\d+)?\]/, '[]')).flat(Infinity))
							let { isMatch, min, max, step } = this.getRangeObj(TZSFResult, /(\d+(\.\d+)?):(\d+(\.\d+)?)\/(\d+(\.\d+)?)/)
							if (isMatch) {
								Object.assign(temp, { min, max, step })
							}
							let result = this.isInRnnge(temp.min, temp.max, temp.step, addtion, false, true)
							addtion = [...new Set(result.addtion)]
						}
						item.specific_param2 = temp
					}
				}
			}
			let optionStr = '';
			for (let i = 1; i < selection.length; i++) {
				const item = selection[i];
				const paramVal = paramMap[item.id];
				if (item.param_type == 1) {
					const op = `[${item.specific_param2.map(i => i.select_value).join(',')}]`;
					optionStr += `-${item.product_param}${paramVal || op}`;
				}
				if (item.param_type == 2) {
					const op = `[${item.specific_param2.min}~${item.specific_param2.max}/${item.specific_param2.step}]`;
					optionStr += `-${item.product_param}${paramVal || op}`;
				}
			}
			const { search_result } = codeData[0];
			this.searchParam = { ...codeData[0], paramMap }
			const firstOp = selection[0];
			if (firstOp.param_type == 2) {
				const op = `[${firstOp.specific_param2.min}~${firstOp.specific_param2.max}/${firstOp.specific_param2.step}]`;
				optionStr = `-${firstOp.product_param}${paramMap[firstOp.id] || op}` + optionStr;
				this.searchParam.search_result = `${search_result}${optionStr}`;
				this.codeData = [this.searchParam];
			} else if (firstOp.param_type == 1) {
				if (paramMap[firstOp.id]) {
					optionStr = `-${firstOp.product_param}${paramMap[firstOp.id]}` + optionStr;
					this.searchParam.search_result = `${search_result}${optionStr}`;
					this.codeData = [this.searchParam];
				} else {
					this.codeData = firstOp.specific_param2.map(i => {
						return {
							...codeData[0],
							search_result: `${search_result}-${firstOp.product_param}${i.select_value}${optionStr}`,
							paramMap: Object.assign({ ...paramMap }, { [firstOp.id]: i.select_value }),
						}
					});
				}
			} else {
				this.codeData = codeData;
			}
		},
		dealTZSF(str, colName, colNameVal, rowName) {
			colNameVal = colNameVal.map(item => isNaN(item) ? item : parseFloat(item))
			if (str.split("\n").length > 1 && !str.endsWith("\n")) {
				str += "\n"
			}
			colName.map((item, index) => {
				window[item] = colNameVal[index]
			})
			const newstr = str.replaceAll("THEN", "")
				.replaceAll(".AND.", " && ")
				.replaceAll(".OR.", " || ")
				.replaceAll(".EQ.", " == ")
				.replaceAll(".GE.", " >= ")
				.replaceAll(".LE.", " <= ")
				.replaceAll(".GT.", " > ")
				.replaceAll(".LT.", " < ")
				.replaceAll(".NE.", " != ")
				.replaceAll("ELSEIF", "if")
				.replace("ENDIF", "")
				.replaceAll("IF", "if")
				.replace("ELSE", "else")
			let bianliang;
			try {
				const newarr = newstr.match(/.*\n/g); //此处 \n 或 \r
				let newarr1 = newstr;
				if (newarr) {
					newarr1 = newarr.map((str, index) => {
						bianliang = str.match(/\s*([A-Z0-9\u4e00-\u9fa5]+)\s*=\s*'?(\S+)'?\s*\n/)
						if (index == 1 && bianliang) {
							window[bianliang[1]] = null;
						}
						if (bianliang && !bianliang[2].endsWith("'")) {
							str = ` ${bianliang[1]}='${bianliang[2]}'\n`
						}
						if (str.startsWith("if") || str.startsWith("else")) {
							return str.replace(/[\n\r\s]+$/, "")
						} else {
							return str.replace(/[\n\r\s]+$/, ";")
						}
					})
					let evalstr = ""
					for (let i = 0; i < newarr1.length; i++) {
						if (newarr1[i].startsWith("if")) {
							let evalResult = eval(evalstr);
							if (evalResult !== undefined) {
								return evalResult
							} else {
								evalstr = newarr1[i]
							}
						} else if (newarr1[i].startsWith("else")) {
							let evalResult = eval(newarr1[i + 1]);
							return evalResult
						} else {
							evalstr += newarr1[i];
						}

						if (i == newarr1.length - 1) {
							let evalResult = eval(evalstr);
							if (evalResult !== undefined) {
								return evalResult
							}
						}
					}
					return undefined;
				} else {
					bianliang = newstr.match(/(\s+)?([A-Z0-9\u4e00-\u9fa5]+)\s*=\s*'?([a-zA-Z0-9,\u4e00-\u9fa5]+).*/)
					if (bianliang) {
						bianliang[1] = bianliang[2];
						window[bianliang[1]] = null;
					}
				}
				let evalResule = eval(newarr1)
				return evalResule;
			} catch (error) {
				this.$message.error(`${rowName}的特征算法解析错误`);
				return null;
			}
		},
		getRangeObj(str, regx) {
			const matchTzsfStr = str.match(regx);
			let min, max, step;
			let isMatch = false;
			if (matchTzsfStr) {
				//合并区间
				isMatch = true
				min = Number.parseFloat(matchTzsfStr[1]);
				max = Number.parseFloat(matchTzsfStr[3]);
				step = Number.parseFloat(matchTzsfStr[5]);
			}
			return { isMatch, min, max, step }
		},
		accMul(arg1, arg2) {
			var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
			try {
				m += s1.split(".")[1].length
			} catch (e) {
			}
			try {
				m += s2.split(".")[1].length
			} catch (e) {
			}
			return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
		},
		//判断是否在范围内,并算出额外数组
		isInRnnge(min, max, step, addtion, checkNum, isout) {
			if (isNaN(checkNum)) {
				return { addtion, checkNumInRangeBool: false }
			}
			checkNum = parseFloat(checkNum)
			//后两个参数分别是 需要验证的值，是否需要计算出addtion？
			let multi;//处理小数取%问题
			let checkNumInRangeBool;
			if (Number.isInteger(step)) {
				multi = 1
			} else {
				multi = Math.pow(10, step.toString().split(".")[1].length)
			}
			//去除范围内的数
			if (isout) {
				addtion = addtion.filter(m => {
					return !(m >= min && m <= max && (m * multi) % (step * multi) == 0)
				})
			}
			if (checkNum || checkNum == 0) {
				checkNumInRangeBool = addtion.some(m => m == checkNum) || (checkNum >= min && checkNum <= max && (this.accMul(checkNum, multi)) % (this.accMul(step, multi)) == 0)
			}
			return {
				addtion,
				checkNumInRangeBool
			}
		},
		async showGoods(item, e) {
			if (!item.goods_id) return;
			if (!item.goodsDetail) {
				const res = await this.$http.get(`/api/goods/${item.goods_id}`)
				item.goodsDetail = res;
			}
			this.goods = item;
			this.detailTop = e.clientY;
			this.showDetail = true;
		},
	}
}
</script>
<style lang="scss" scoped>
.common-top-search {
	.main {
		display: flex;
		align-items: center;

		.search-container {
			display: flex;
			align-items: center;
			height: 44px;
			margin-left: 20px;
			margin-right: 30px;

			.input-container {
				// width: 430px;
				width: 390px;
				height: 100%;
				position: relative;

				.search-result {
					position: absolute;
					z-index: 999;
					box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
					left: 0;
					top: 44px;
					background: #ffffff;
					width: 100%;
					transition: all .3s;
					max-height: 280px;
					overflow-y: scroll;

					&::-webkit-scrollbar {
						width: 7px;
					}

					&::-webkit-scrollbar-thumb {
						border-radius: 10px;
						background: rgba(0, 0, 0, 0.20);
					}

					&::-webkit-scrollbar-thumb:window-inactive {
						background: rgba(200, 200, 200, 0.4);
					}

					.list {

						//padding: 10px 0;
						.list-item {
							padding: 0 20px;
							line-height: 34px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							/* 限制在一个块元素显示的文本的行数 */
							-webkit-box-orient: vertical;
							/* 垂直排列 */
							word-break: break-all;
							/* 内容自动换行 */
							cursor: pointer;
							//background: #f1d6d6;
							color: #000000;

							//color: #f1d6d6;
							&:hover {
								background-color: #f1d6d6;
							}
						}
					}
				}
			}

			input {
				height: 100%;
				border: 1px solid #D9262C;
				border-radius: 2px 0 0 2px;
				outline: none;
				width: 100%;
				padding: 0 15px;
			}

			.search-btn {
				height: 100%;
				width: 100px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #D9262C;
				border-radius: 0 2px 2px 0;
				color: #ffffff;
				cursor: pointer;

				.iconfont {
					font-size: 20px;
				}
			}
		}
	}

	.hotwordsContainer {
		margin-top: 10px;
		margin-left: 20px;
		height: 14px;
		box-sizing: border-box;
	}

	.hotwords {
		display: flex;
		flex-wrap: wrap;
		color: #999999FF;

		.hot-item {
			margin-right: 20px;
			cursor: pointer;
		}
	}

	.ksbj {
		margin-left: 10px;
		font-weight: 500;
		cursor: pointer;

		&:hover {
			color: rgb(217, 38, 44, .7);
		}
	}

	.productDetailContent {
		display: flex;
		position: fixed;
		top: 120px;
		left: 800px;
		width: 553px;
		max-height: 600px;
		background-color: #fff;
		box-shadow: 6px 2px 8px 0 rgba(0, 0, 0, .12);
		border-radius: 4px;
		border: 1px solid #eee;
		padding: 20px;
		z-index: 1000;
		cursor: pointer;

		.contentLeft {
			width: 132px;
			height: 132px;
			margin-right: 20px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.contentRight {
			flex: 1;

			.goods-title {
				font-size: 14px;
				color: #333;
				line-height: 28px;
			}

			.goods-item {
				display: flex;
				line-height: 28px;
				font-size: 12px;
				color: #666;

				.label {
					width: 61px;
					flex-shrink: 0;
				}

				.light-color {
					color: #3385FF;
				}

				.content {
					display: flex;
					flex-wrap: wrap;
				}

				.data-item {
					margin-right: 10px;
				}

				.one-line {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					width: 250px;
					overflow: hidden;
					text-overflow: ellipsis;
					// white-space: nowrap;
					word-break: break-all;
					-webkit-line-clamp: 3;
				}
			}
		}
	}
}
</style>
