<template>
  <div class="home" style="background-color: #f5f5f5ff">
    <MainHeader />
    <CommonTop />
    <div class="cat-container" style="background-color: #fff;width: 100%;border-bottom: 2px solid #D9262C;">
      <Nav :height="'482px'" :isShow="true" style="width: 1200px;margin: 0 auto;" />
    </div>
    <div class="banner-section">
      <swiper class="banner-swiper" ref="mySwiper" :options="swiperOptions">
        <swiper-slide

          v-for="item in bannerData"

          :key="item.id">
          <el-image @click="jumpHandle(item)" :src="item.cover" style="width: 100%; height: 100%;" alt=""> </el-image>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="user-section">
        <div class="top">
          <div style="display:flex;align-items: center">
            <div class="avatar">
              <img :src="userInfo && userInfo.avatar || defaultAvatar" alt="">
            </div>
            <div class="welcome">Hi~{{userInfo && userInfo.name || '鹏友'}}，欢迎来到鹏隆！</div>
          </div>
          <div v-if="userInfo && userInfo.id" class="actions">
            <div v-if="userInfo.company_type != 1" @click="$router.push('/user/account/company')" class="upgradeEnterprise">升级企业用户</div>
            <!-- <router-link class="btn login" to="/">报价</router-link>
            <router-link class="btn regist" to="/">订单</router-link> -->
          </div>
          <div v-else class="actions">
            <router-link class="btn login" to="/login">登录</router-link>
            <router-link class="btn regist" to="/regist">注册</router-link>
          </div>
        </div>
        <div class="bottom">
          <div class="btn baojia" style="background-color: #D9262C;color: #fff" @click="$router.push(`/quote`)">快速报价</div>
          <div class="import" @click="$router.push(`/quote/upload`)" style="border-bottom: 1px solid #e5e5e5;padding-bottom: 14px;">
            <span class="icon iconfont icon-Excel"></span>
            Excel批量导入
          </div>
          <div class="actions">
            <div class="item" @click="$router.push('/user/order')">
              <img src="../assets/images/common/orderIcon.png" alt="">
              <span style="color: #A8A8A8;font-size: 14px">我的订单</span>
            </div>
            <div class="item" @click="$router.push('/user/quote')">
              <img src="../assets/images/common/quotationIcon.png" alt="">
              <span style="color: #A8A8A8;font-size: 14px">我的报价</span>
            </div>
            <div class="item" @click="$router.push('/news/acticle/109')">
              <img src="../assets/images/common/designPluginsIcon.png" alt="">
              <span style="color: #A8A8A8;font-size: 14px">设计插件/3D模型</span>
              <div style="margin-top: 6px;color: #D9262C;font-size: 11px">立即下载</div>
            </div>
            <div class="item" @click="$router.push('/news/acticle/108')">
              <img src="../assets/images/common/catalogBookIcon.png" alt="">
              <span style="color: #A8A8A8;font-size: 14px">目录书申请</span>
              <div style="margin-top: 6px;color: #D9262C;font-size: 11px">立即申请</div>
            </div>
            <!-- <div class="item">
              <span class="icon iconfont icon-jifen"></span>
              我的积分
            </div> -->
          </div>
          <div style="margin-top: 10px;">
            <div style="display: flex;flex-direction: row;justify-content: space-between">
              <div style="border-left: 2px solid #D9262C;padding-left: 10px;color: black;font-size: 17px">最新公告</div>
              <div style="color: #999;cursor: pointer;font-size: 17px" @click="$router.push('/news/0/57')">更多 ></div>
            </div>
            <div style="min-height: 80px;margin-top: 10px">
              <div v-for="item in noticeList" :key="item.id" @click="$router.push(`/news/acticle/${item.id}`)"
                   class="newsFourClass"
              >{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hot-section">
      <div style="margin: 0 auto; width: 1200px;display:flex;">
        <div  v-for="(item, index) in bannerRecommendSwiper.slice(0, 4)" :key="index" :style="{ 'margin-right': index != 3 ? '10px' : '0px' }" style="width: 300px;height: 160px">
          <el-image @click="jumpHandle(item)" style="height: 100%;width: 100%" :src="item.cover"></el-image>
        </div>
      </div>

<!--      <div class="page-content-wrap">-->
<!--        <div  v-for="(item, index) in recommendSwiper" :key="index" style="width: 300px;">-->
<!--          <el-image :src="item.cover"></el-image>-->
<!--        </div>-->
<!--        <div @click="slideHandle('prev')" class="prev"><span class="icon iconfont icon-hangdongjiantou"></span></div>-->
<!--        <swiper class="hot-swiper" ref="myHotSwiper" :options="hotSwiperOptions">-->
<!--          <swiper-slide-->
<!--            :goods_id="item.id"-->
<!--            class="hot-slide-item"-->
<!--            v-for="(item, index) in recommendSwiper"-->
<!--            :key="index">-->
<!--            <div class="info">-->
<!--              <div class="name">{{item.name}}</div>-->
<!--              <div class="desc" style="opacity: 0;">产品描述</div>-->
<!--              <div v-if="item.remark" class="time">{{item.remark}}</div>-->
<!--            </div>-->
<!--            <div class="cover">-->
<!--              <img :src="item.cover" alt="">-->
<!--            </div>-->
<!--          </swiper-slide>-->
<!--        </swiper>-->
<!--        <div @click="slideHandle('next')" class="next"><span class="icon iconfont icon-hangdongjiantou"></span></div>-->
<!--      </div>-->
    </div>
    <div v-if="newsList" class="latest-news" style="padding-top: 20px;">
      <div class="page-content-wrap" style="background-color: #ffffffff;padding: 10px;">
        <div class="left">
          <img class="icon" src="../assets/images/common/hotInformation.png" alt="">
          <div class="title">最新资讯</div>
<!--          <div class="label">活动/公告通知， 讯息一手掌握</div>-->
        </div>
        <div class="right">
          <div class="message-list" style="display: flex;flex-wrap: wrap;flex-direction: row;width: 1000px;justify-content: space-between">
            <div v-for="item in newsList.slice(0, 4)" :key="`message-list-${item.id}`"
                 style="display: flex;flex-direction: row;margin-left: 20px;width: 480px;cursor: pointer"
                 @click="goPage(item)"
            >
              <div>
                <img class="icon" src="../assets/images/common/newIcon.png" alt="">
              </div>
              <div style="margin-left: 10px;overflow: hidden;text-overflow: ellipsis;white-space:nowrap;padding-top: 4px">{{item.name}}</div>
            </div>
            <!-- <div @click="goPage(item)" v-for="(item, index) in newsList" :key="index" class="message-item">
              <span class="tag">NEW</span>
              <span>{{item.name}}</span>
            </div> -->
<!--            <vue-seamless-scroll>-->
<!--              <vue-seamless-scroll :data="newsList" :class-option="optionLeft" class="seamless-warp2" style="height: 14px;">-->
<!--                <ul style="width: auto; white-space: nowrap;">-->
<!--                  <li @click="goPage(item)" v-for="(item, index) in newsList" :key="index" class="message-item" style="float: left;">-->
<!--                    <span class="tag">NEW</span>-->
<!--                    <span>{{item.name}}</span>-->
<!--                  </li>-->
<!--                </ul>-->
<!--             </vue-seamless-scroll>-->
<!--            </vue-seamless-scroll>-->
          </div>
        </div>
        <div style="color: #999;cursor: pointer" @click="$router.push('/news/0')">更多></div>
      </div>
    </div>
    <div id="recommend-section" class="recommend-section category-goods">
      <div class="page-content-wrap">
        <div class="recommend-header">
          <div class="title">推荐商品</div>
          <div class="cat-list">
            <div
              v-for="(item, index) in recommendCat" :key="index"
              @click="recommendType = item.type"
              :class="[recommendType === item.type ? 'active' : '', 'cat-item']">{{item.label}}</div>
<!--            <div @click="recommendType = 5" :class="[recommendType === 5 ? 'active' : '', 'cat-item']" style="text-align: right" >-->
<!--              <span >更多 ></span>-->
<!--                          <span class="icon iconfont icon-hangdongjiantou"></span>-->
<!--            </div>-->
          </div>
        </div>
<!--        <div class="recommend-banner">-->
<!--          <img @click="jumpGoodsRecommend" style="cursor: pointer;" :src="recommendCatList[0] && recommendCatList[0].cover" alt="">-->
<!--        </div>-->

        <div class="goods-list" style="display: flex;flex-direction: row;margin-top: 20px">
          <div v-if="bannerRecommendCatList.length && bannerRecommendCatList[0].cover"
               style="width: 360px;height: 600px;border-bottom: 1px solid #999; border-right: 1px solid #999;"
          >
            <img @click="jumpHandle(item)" style="cursor: pointer;width: 100%;height: 100%" :src="bannerRecommendCatList[0].cover" alt="">
          </div>
          <div >
            <div v-if="recommendCatList[0]" class="left" style="display: flex; flex-direction: row;width: 840px;flex-wrap: wrap">
              <div @click="jumpDetail(item.id)"
                   v-for="(item, index) in recommendCatList[0].goods.slice(0, 6)" :key="index"
                   class="goods-item"
                   style="width: 280px"
              >
                <div class="goods-img">
                  <img :src="item.cover" alt="" class="">
                </div>
                <div class="goods-info">
                  <div class="goods-name">{{item.name}}</div>
<!--                  <div class="goods-name" style="font-size: 12px;color: #999;margin-top: 4px">{{item.name}}</div>-->
<!--                  <div class="view-btn">去看看</div>-->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="bannerFootRecommendCatList.length" style="margin-top: 38px;">
          <el-image  @click="jumpHandle(bannerFootRecommendCatList[0])"  :src="bannerFootRecommendCatList[0].cover"></el-image>
        </div>
<!--        <div class="goods-list">-->
<!--          <div v-if="recommendCatList[0]" class="left">-->
<!--            <div @click="jumpDetail(item.id)" v-for="(item, index) in recommendCatList[0].goods.slice(0, 2)" :key="index" class="goods-item">-->
<!--              <div class="goods-img">-->
<!--                <img :src="item.cover" alt="" class="">-->
<!--              </div>-->
<!--              <div class="goods-info">-->
<!--                <div class="goods-name">{{item.name}}</div>-->
<!--                <div class="view-btn">去看看</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div v-if="recommendCatList[0]" class="right">-->
<!--            <div @click="jumpDetail(item.id)" v-for="(item, index) in recommendCatList[0].goods.slice(2, recommendCatList[0].goods.length)" :key="index" class="goods-item">-->
<!--              <div class="goods-img">-->
<!--                <img :src="item.cover" alt="" class="">-->
<!--              </div>-->
<!--              <div class="goods-info">-->
<!--                <div class="goods-name">{{item.name}}</div>-->
<!--                &lt;!&ndash; <div class="desc">悬臂销·铰链销</div> &ndash;&gt;-->
<!--                <div class="view-btn">去看看</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <catBar :catList="categoryGoods" class="home-cat-bar" />
    </div>
    <div v-for="(item, index) in categoryGoods" :key="item.id" :class="['list-1-container', 'category-goods', 'category-goods-' + item.id]">
      <List1 :data="item" :backgroundImage="index % 2 === 0 ?'http://temp.polon.shop/template/pc/rainbow/static/images/back1.png' : ''" />
      <div v-if="bannerFootData.length >= index + 1" style="text-align: center;  background-color: #f5f5f5;">
        <el-image style="width: 1200px;" @click="jumpHandle(bannerFootData[index])"  :src="bannerFootData[index].cover"></el-image>
      </div>
    </div>
    <!-- <div class="list-2-container">
      <List2 />
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import MainHeader from '@/components/header/main.vue'
import CommonTop from '@/components/commonTop/index.vue'
import Nav from '@/components/nav/index.vue'
import CatBar from '@/components/catBar/index.vue'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import List1 from '@/components/home/list-1.vue'
// import List2 from '@/components/home/list-2.vue'
import defaultAvatar from '../assets/images/common/default_avatar.png'
import { mapState } from 'vuex'
// import vueSeamlessScroll from 'vue-seamless-scroll'
import homeFootIcon from '@/assets/images/common/homeFootIcon.png'
export default {
  name: 'Home',
  components: {
    MainHeader,
    CommonTop,
    Nav,
    CatBar,
    swiper,
    swiperSlide,
    List1,
    // vueSeamlessScroll
    // List2
  },
  data () {
    const _this = this
    return {
      defaultAvatar: defaultAvatar,
      homeFootIcon,
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',    //与slot="pagination"处 class 一致
          clickable: true,            //轮播按钮支持点击
        },
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false
        },
        loop: false
      },
      bannerData: [],
      hotSwiperOptions: {
        slidesPerView: 3,
        slidesPerGroup : 3,
        speed: 1000,
        freeMode: false,
        // spaceBetween: 10,
        // 自动轮播
        autoplay: false,
        // 开启循环模式
        loop: true,
        on: {
          click: function () {
            console.log(this.realIndex)
            _this.$router.push({ path: '/goods/detail', query: { id: _this.recommendSwiper[this.realIndex].id }})
          }
        }
      },
      recommendSwiper: [],
      messageList: [
        { title: '关于2021年元旦放假通知' },
        { title: '公告通知' },
        { title: '关于2021年元旦放假通知' },
        { title: '公告通知' },
        { title: '关于2021年元旦放假通知' },
        { title: '公告通知' },
        { title: '关于2021年元旦放假通知' },
        { title: '公告通知' },
        { title: '关于2021年元旦放假通知' },
        { title: '公告通知' },
        { title: '鹏隆实业商城公告通知' }
      ],
      recommendList: [],
      recommendType: 2,
      noticeMoveTimer: null,
      recommendCat: [
        { label: '快速交期', type: 2 },
        { label: '特惠专区', type: 6 },
        { label: '新品上市', type: 3 },
        { label: '热销产品', type: 4 },
        // { label: '更多 >', type: 5 }
      ],
      recommendIndex: 0,
      categoryGoods: [],
      newsList: null,
      noticeList: [],
      bannerFootData: [],
      bannerRecommendCatList: [],
      bannerRecommendSwiper: [],
      bannerFootRecommendCatList: [],
    }
  },
  created () {
    this.getBanner()
    this.getFootBanner()
    this.getBannerRecommendSwiper()
    this.getBannerRecommendCatList()
    this.getBannerFootRecommendCatList()
    this.getRecommendSwiperData()
    this.getRecommend()
    this.getCategoryGoods()
    // console.log(process.env.VUE_APP_BASEURL)
    this.getNews()
    this.getNoticeList()
  },
  computed: {
    ...mapState([
      'userInfo'
    ]),
    recommendCatList () {
      return this.recommendList.filter(item => {
        return item.type == this.recommendType
      })
    },
    optionLeft () {
        return {
          direction: 2,
          limitMoveNum: 2
        }
      }
  },
  mounted () {
    // this.$nextTick(() => {
    //   this.noticeMove()
    // })
  },
  beforeDestroy () {
    clearInterval(this.noticeMoveTimer)
  },
  methods: {
    jumpHandle (item) {
      if (item.jump_type == 1) {
        window.open(item.jump_url, '_blank')
      }
    },
    async getNoticeList() {
      const { data } = await this.$http.get('/api/acticle', { page: 1, per_page: 3, classifyId: 57 })
      this.noticeList = data || []
    },
    goPage(data) {
        this.$router.push(`/news/acticle/${data.id}`)
    },
    // 获取新闻资讯
    async getNews () {
      const res = await this.$http.get('/api/acticle', { page: 1, per_page: 10000, type: 0 })
      console.log('新闻资讯', res)
      this.newsList = res.data
    },
    // 跳转推荐商品列表
    jumpGoodsRecommend () {
      this.$router.push({ path: '/goods/recommend', query: { type: this.recommendType } })
    },
    // 分类商品
    async getCategoryGoods () {
      const res = await this.$http.get('/api/category-goods', {})
      console.log(res)
      this.categoryGoods = res
    },
    // 跳转详情
    jumpDetail (id) {
      this.$router.push({ path: '/goods/detail', query: { id: id }})
    },
    // 获取轮播推荐商品
    async getRecommendSwiperData () {
      const res = await this.$http.get('/api/recommand-goods/1', { page: 1, per_page: 1000 })
      console.log(res)
      this.recommendSwiper = res.goods.data
    },
    // 获取推荐商品
    async getRecommend () {
      const res = await this.$http.get('/api/recommand-goods', {})
      console.log(res)
      this.recommendList = res
    },
    // 获取banner
    async getBanner () {
      const res = await this.$http.get('/common/banners', { type: 10 })
      this.bannerData = res
    },
    async getFootBanner () {
      const res = await this.$http.get('/common/banners', { type: 20 })
      this.bannerFootData = res
    },
    async getBannerRecommendCatList () {
      const res = await this.$http.get('/common/banners', { type: 13 })
      this.bannerRecommendCatList = res
    },
    async getBannerFootRecommendCatList () {
      const res = await this.$http.get('/common/banners', { type: 14 })
      this.bannerFootRecommendCatList = res
    },
    async getBannerRecommendSwiper () {
      const res = await this.$http.get('/common/banners', { type: 30 })
      this.bannerRecommendSwiper = res
    },
    startMove () {
      this.noticeMove()
    },
    stopMove () {
      clearInterval(this.noticeMoveTimer)
    },
    noticeMove () {
      const _this = this
      if (this.noticeMoveTimer) {
        clearInterval(this.noticeMoveTimer)
      }
      this.noticeMoveTimer = setInterval(() => {
        let marginLeft = document.querySelector('.latest-news .message-list').style.marginLeft
        if (marginLeft === '') {
          marginLeft = 0
        }
        marginLeft = parseInt(marginLeft)
        let firstEleWidth = document.querySelector('.latest-news .message-list .message-item').offsetWidth
        if (marginLeft + firstEleWidth < 0) {
          const item = _this.messageList.shift()
          _this.messageList.push(item)
          marginLeft = 0
        }
        document.querySelector('.latest-news .message-list').style.marginLeft = (marginLeft - 1) + 'px'

      }, 20)
    },
    slideHandle (type) {
      if (type === 'prev') {
        this.$refs.myHotSwiper.swiper.slidePrev()
      } else if (type === 'next') {
        this.$refs.myHotSwiper.swiper.slideNext()
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.category-goods {
  background-color: #f5f5f5;
}

.newsFourClass:hover {
  color: #D9262C;
}

.newsFourClass{
  //margin: 10px 0;
  line-height: 24px;
  font-weight: 500;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #333333;
  font-size: 14px;
  white-space: nowrap;
}

.banner-section {
  background-color: #f5f5f5;
  width: 100%;
  min-width: 1200px;
  height: 482px;
  position: relative;
  .user-section {
    width: 270px;
    position: absolute;
    top: 242px;
    height: 480px;
    right: calc((100vw - 1220px) / 2);
    transform: translateY(-50%);
    background-color: #fff;
    border-radius: 3px;
    z-index: 10;
    padding: 8px 10px;
    .top {
      //border-bottom: 1px dotted #e5e5e5;
      //padding-bottom: 15px;
      .avatar {
        width: 50px;
        height: 50px;
        // background-color: #ccc;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto;
        //padding-top: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .welcome {
        width: 180px;
        //text-align: center;
        padding: 0 10px;
        color: #666;

        //margin-top: 15px;
        line-height: 1.3;
      }
      .actions {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        .upgradeEnterprise {
          background: url("../assets/images/common/upgradeEnterprise.png");
          width: 250px;
          text-align: center;
          //font-size: 12px;
          color: #333;
          padding: 10px;
        }
        .btn {
          width: 90px;
          height: 32px;
          border: 1px solid #3385FF;
          color: #3385FF;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
          cursor: pointer;
          text-decoration: none;
          &:hover {
            opacity: .8;
          }
          &.login {
            //color: #3385FF;
            //background-color: #3385FF;
            background-color: #f5f5f5;
            margin-right: 10px;
            border: none;
            //color: #ffffff;
            color: black;
          }
          &.regist {
            background-color: #fdd000ff;
            //margin-right: 10px;
            border: none;
            color: black;
          }

        }
      }
    }
    .bottom {
      padding-top: 15px;
      .btn.baojia {
        height: 38px;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFCA0A;
        cursor: pointer;
      }
      .import {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        cursor: pointer;
        color: #999999;
        .iconfont {
          color: #00a13d;
          margin-right: 6px;
        }
      }
      .actions {
        display: flex;
        justify-content: space-around;
        align-items: center;
        //margin-top: 20px;
        padding-bottom: 10px;
        flex-wrap: wrap;
        width: 240px;
        border-bottom: 1px solid #e5e5e5;
        .item {
          display: flex;
          width: 120px;
          margin-top: 10px;
          flex-direction: column;
          justify-content: center;
          font-size: 12px;
          align-items: center;
          cursor: pointer;
          .iconfont {
            margin-bottom: 8px;
            font-size: 18px;
          }
        }
      }
    }
  }
  .banner-swiper {
    //padding-top: 1px;
    width: 660px;
    height: 100%;
    //background-color: black;
    .swiper-slide {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
}

::v-deep  .banner-swiper .swiper-pagination-bullet-active {
  background: #D9262C !important;
}

.hot-section {
  background-color: #f5f5f5;
  padding-top: 20px;
  .page-content-wrap {
    height: 166px;
    background-color: #ffffff;
    border-radius: 2px;
    display: flex;
    align-items: center;
    .next, .prev {
      width: 40px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        .iconfont {
          color: #3385FF;
        }
      }
      .iconfont {
        font-size: 24px;
        color: #999;
      }
    }
    .prev .iconfont {
      transform: rotate(180deg);
    }
    .hot-swiper {
      height: 100%;
      flex: 1;
      .hot-slide-item {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        // &:nth-child(2n) {
        //   &::after {
        //     height: 90px;
        //     width: 1px;
        //     background-color: #e3e3e3;
        //     content: "";
        //     display: block;
        //     position: absolute;
        //     right: 0;
        //     top: 50%;
        //     transform: translateY(-50%);
        //   }
        //   &::before {
        //     height: 90px;
        //     width: 1px;
        //     background-color: #e3e3e3;
        //     content: "";
        //     display: block;
        //     position: absolute;
        //     left: 0;
        //     top: 50%;
        //     transform: translateY(-50%);
        //   }
        // }
        .info {
          width: 200px;
          .name {
            font-size: 15px;
            font-weight: bold;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.5;
          }
          .desc {
            font-size: 12px;
            color: #999999;
            margin-top: 9px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .time {
            width: 108px;
            height: 36px;
            border-radius: 3px;
            background-color: #3385FF;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: #ffffff;
            margin-top: 15px;
            line-height: 36px;
            overflow : hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* 限制在一个块元素显示的文本的行数 */
            -webkit-box-orient: vertical; /* 垂直排列 */
            word-break: break-all;  /* 内容自动换行 */
            padding: 0 5px;
            text-align: center;
          }
        }
        .cover {
          width: 90px;
          height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          // overflow: hidden;
          margin-left: 30px;
          &:hover {
            img {
              width: 110%;
              height: 110%;
            }
          }
          img {
            width: 100%;
            height: 100%;
            transition: all .3s;
          }
        }
      }
    }
  }
}
.latest-news {
  background-color: #f5f5f5;
  .page-content-wrap {
    padding-top: 30px;
    display: flex;
    //align-items: center;
    .left {
      display: flex;
      align-items: center;
      .icon {
        vertical-align: middle;
        width: 30px
      }
      .title {
        font-size: 18px;
        margin-left: 10px;
        font-weight: bold;
        width: 40px;
        line-height: 26px;
      }
      .label {
        font-size: 14px;
        margin-left: 10px;
      }
    }
    .right {
      flex: 1;
      overflow: hidden;
      margin-left: 30px;
      .message-list {
        // transition: all 1s;
        white-space: nowrap;
        .message-item {
          display: inline-block;
          padding-right: 30px;
          cursor: pointer;
          .tag {
            background-color: red;
            color: #ffffff;
            border-radius: 4px;
            font-size: 12px;
            line-height: 14px;
            padding: 0 2px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.recommend-section {
  background-color: #f5f5f5;
  //padding: 38px 0;
  padding-top: 38px;
  position: relative;
  .home-cat-bar {
    position:absolute;
    left: calc((100vw - 1200px) / 2 - 90px);
    top: 50px;
  }
  .recommend-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 28px;
      font-weight: bold;
    }
    .cat-list {
      display: flex;
      align-items: center;
      .cat-item {
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
        border-radius: 2px;
        cursor: pointer;
        &.active {
          //border: 1px solid #D9262C;
          color: #D9262C;
        }
      }
    }
  }
  .recommend-banner {
    overflow: hidden;
    height: 226px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 2px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .goods-list {
    display: flex;
    margin-top: 15px;
    margin-bottom: 20px;
    border-top: 1px solid #999;
    border-left: 1px solid #999;
    .left {
      width: 308px;
      transition: all .2s linear;
      //&:hover {
      //  box-shadow: 0 15px 30px rgba(0,0,0,.1);
      //  transform: translate3d(0,-2px,0)
      //}
      .goods-item {
        background-color: #ffffff;
        padding: 30px;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        //padding-bottom: -1px;
        //padding-right: -1px;
        align-items: center;
        height: 300px;
        cursor: pointer;
        position: relative;
        &::after {
          display: block;
          content: "";
          width: 250px;
          height: 1px;
          //background-color: #e0e0e0;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
        &:hover {
          .goods-img {
            img {
              transform: scale(1.2);
            }
          }
        }
        .goods-img {
          flex: 1;
          max-width: 180px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 100%;
          }
        }
        .goods-info {
          display: flex;
          flex-direction: column;
          //align-items: center;
          margin-top: 30px;
          .goods-name {
            font-size: 16px;
            font-weight: bold;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
	          line-height: 24px;
	          height: 24px;
          }
          .desc {
            color: #666666;
            font-size: 12px;
            margin-top: 10px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .view-btn {
            color: #ffffff;
            background-color: #D9262C ;
            line-height: 24px;
            font-size: 12px;
            border-radius: 12px;
            text-align: center;
            width: 60px;
            margin-top: 15px;
          }
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .goods-item {
        margin-left: 15px;
        width: 431px;
        height: 170px;
        background-color: #ffffff;
        margin-top: 15px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        padding: 30px;
        cursor: pointer;
        transition: all .2s linear;
        &:hover {
           box-shadow: 0 15px 30px rgba(0,0,0,.1);
          transform: translate3d(0,-2px,0)
        }
        &:nth-child(-n + 2) {
          margin-top: 0;
        }
        .goods-img {
          width: 110px;
          height: 110px;
          display: flex;
          justify-content: center;
          align-items: center;
          // border: 1px solid red;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .goods-info {
          margin-left: 20px;
          flex: 1;
          .goods-name {
            font-size: 16px;
            font-weight: bold;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .desc {
            font-size: 12px;
            color: #666666;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            margin-top: 10px;
          }
          .view-btn {
            color: #ffffff;
            background-color: #3385FF;
            line-height: 24px;
            font-size: 12px;
            border-radius: 12px;
            text-align: center;
            width: 60px;
            margin-top: 15px;
          }
        }
      }
    }
  }
}
</style>
